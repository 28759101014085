<template>
  <section class=" fp">
    <h1 class="display-4 text-center p-3">Followup</h1>
    <b-tabs pills card justified align="center" class="" content-class="mt-3">
      <b-tab title="Leads">

        <div class="d-flex flex-wrap justify-content-center">
          <div class="mt-2 ml-md-2 card p-2" style="min-width: 300px" v-for="l of leads" :key="l.mobile">
            <div class="list-group list-group-flush">
              <div class="list-group-item">
                <font-awesome-icon icon="user"></font-awesome-icon>
                {{ l.name }}
              </div>
              <a class="list-group-item" :href="`tel:+91${l.mobile}`">
                <font-awesome-icon icon="phone"></font-awesome-icon>
                {{ l.mobile }}</a>
              <div class="list-group-item">
                <font-awesome-icon icon="calendar"></font-awesome-icon>
                {{ l.date }}
              </div>
              <div class="list-group-item">Account?: {{ l.account }}</div>
            </div>

            <div class="d-flex justify-content-end">
              <b-button size="md" @click="followup('L', l)" class="mt-3">
                <font-awesome-icon icon="phone"></font-awesome-icon>
                Call Now
              </b-button>
            </div>

          </div>
        </div>
      </b-tab>
      <b-tab title="New Customers">
        <div class="d-flex flex-wrap justify-content-center">
          <div class="mt-2 ml-md-2 card p-2" style="min-width: 300px" v-for="l of newCustomers" :key="l.mobile">
            <div class="list-group list-group-flush">
              <div class="list-group-item">
                <font-awesome-icon icon="user"></font-awesome-icon>
                {{ l.name }}
              </div>
              <div class="list-group-item">
                <font-awesome-icon icon="building"></font-awesome-icon>
                {{ l.house }} {{ l.address }} {{ l.deliveryArea.name }}
              </div>
              <a class="list-group-item" :href="`tel:+91${l.mobile}`">
                <font-awesome-icon icon="phone"></font-awesome-icon>
                {{ l.mobile }}</a>
              <div class="list-group-item"># of Orders: {{ l.orderCount }}</div>
              <div class="list-group-item">
                <font-awesome-icon icon="calendar"></font-awesome-icon>
                {{ l.latestOrderAt }}
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <b-button size="md" @click="followup('C', l)" class="mt-3">
                <font-awesome-icon icon="phone"></font-awesome-icon>
                Call Now
              </b-button>
            </div>

          </div>
        </div>
      </b-tab>
      <b-tab title="Old Customers">
        <div class="d-flex flex-wrap justify-content-center">
          <div class="mt-2 ml-md-2 card p-2" style="min-width: 300px" v-for="l of oldCustomers" :key="l.mobile">
            <div class="list-group list-group-flush">
              <div class="list-group-item">
                <font-awesome-icon icon="user"></font-awesome-icon>
                {{ l.name }}
              </div>
              <div class="list-group-item">
                <font-awesome-icon icon="building"></font-awesome-icon>
                {{ l.house }} {{ l.address }} {{ l.deliveryArea.name }}
              </div>
              <a class="list-group-item" :href="`tel:+91${l.mobile}`">
                <font-awesome-icon icon="phone"></font-awesome-icon>
                {{ l.mobile }}</a>
              <div class="list-group-item"># of Orders: {{ l.orderCount }}</div>
              <div class="list-group-item">
                <font-awesome-icon icon="calendar"></font-awesome-icon>
                {{ l.latestOrderAt }}
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <b-button size="md" @click="followup('C', l)" class="mt-3">
                <font-awesome-icon icon="phone"></font-awesome-icon>
                Call Now
              </b-button>
            </div>

          </div>
        </div>
      </b-tab>
    </b-tabs>
    <b-modal id="follow-up" hide-footer title="Customer Followup">
      <h4 class="mb-3">Name: {{ name }}</h4>
      <h4 class="mb-3">Mobile: {{ followupUpdate.mobile }}</h4>
      <b-form-group label="Feedback">
        <b-form-select v-model="followupUpdate.reason" :options="reasons" text-field="val" value-field="id"></b-form-select>
      </b-form-group>
      <b-form-group label="Comment">
        <b-form-textarea v-model="followupUpdate.comment"></b-form-textarea>
      </b-form-group>
      <b-button size="lg" variant="primary" @click="updateFollowUp">
        Save
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'

export default {
  name: 'FollowUp',
  data() {
    return {
      name: '',
      leads: [],
      newCustomers: [],
      oldCustomers: [],
      followupUpdate: {
        mobile: '',
        reason: '',
        comment: ''
      },
      reasons: [
        {id: 'NO_ANSWER', val: 'Not Answered'},
        {id: 'NOT_HAPPY_PRODUCT', val: 'Not Happy with Product'},
        {id: 'NOT_HAPPY_SERVICE', val: 'Not Happy With Service'},
        {id: 'EXPENSIVE', val: 'Expensive'},
        {id: 'OUT_OF_TOWN', val: 'Out of Town'},
      ]
    }
  },
  async created() {
    await this.loadFollowup()
  },
  methods: {
    async loadFollowup() {
      let r = await axios.get("/api/admin/followup")
      this.leads = r.data.leads
      this.newCustomers = r.data.newCustomers
      this.oldCustomers = r.data.oldCustomers
    },
    async followup(what, whom) {
      if (what === 'C') {
        this.name = whom.name
      } else {
        this.name = whom.name
      }
      this.followupUpdate.reason = ''
      this.followupUpdate.mobile = whom.mobile
      this.$bvModal.show('follow-up')
    },
    async updateFollowUp() {
      await axios.post("/api/admin/followup", this.followupUpdate)
      this.$bvModal.hide('follow-up')
      await this.loadFollowup()
    }
  }
}
</script>
<style>
.fp .tabs .card-header ul.nav {
  flex-direction: column;
}

@media (min-width: 576px) {
  .fp .tabs .card-header ul.nav {
    flex-direction: row;
  }
}
</style>