<template id="new-product">
  <b-button size="sm" variant="info" @click.prevent="openDialog">
    <slot></slot>
    <b-modal :id="'new-product-'+id" :title="title" hide-footer size="xl">
      <b-form-group label="Name of Product (for website)">
        <b-form-input v-model="pr.webName" type="text" required placeholder="Name"></b-form-input>
      </b-form-group>

      <b-form-group label="Name of Product">
        <b-form-input v-model="pr.name" type="text" required placeholder="Name"></b-form-input>
      </b-form-group>

      <b-form-group label="SubTitle">
        <b-form-input v-model="pr.subTitle" type="text"
                      placeholder="Freshly Milled/Freshly Ground/Something"></b-form-input>
      </b-form-group>

      <b-form-group label="BarCode">
        <b-form-input v-model="pr.barcode" type="text"
                      placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group label="Theme Color" description="color of product">
        <b-form-input v-model="pr.color" type="color"></b-form-input>
      </b-form-group>
      <b-input-group prepend="Ingredients (Comma)" class="mb-2">
        <b-form-input v-model="ings"></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="makeIngs">Set</b-button>
        </b-input-group-append>
      </b-input-group>

      <b-form-group label="Collections">

        <v-select name="collections"
                  label="name"
                  v-model="pr.collections"
                  multiple
                  :reduce="c => c.id"
                  :options="allCollections"></v-select>
      </b-form-group>

      <b-form-group label="Ingredients">
        <b-form-tags input-id="tags-basic" v-model="pr.ingredients" class="mb-2"></b-form-tags>
      </b-form-group>

      <b-form-group label="Keywords">
        <b-form-tags input-id="kws-basic" v-model="pr.keywords" class="mb-2"></b-form-tags>
      </b-form-group>

      <b-form-group label="Tags (max 3)">
        <v-select name="tags" v-model="pr.tags"
                  multiple
                  :options="allTags"></v-select>
      </b-form-group>

      <b-form-group label="Description">
        <vue-editor
            :editorToolbar="customToolbar"
            v-model="pr.description"></vue-editor>
      </b-form-group>
      <hr>
      <file-upload v-model="img" :resize="true" @input="addNewImage"></file-upload>
      <div class="d-flex flex-column justify-content-start mt-2 mb-2">

        <small>Drag and Drop to Rearrange</small>
        <draggable v-model="pr.images" group="people">

          <transition-group>
            <div
                class="d-flex w-100 justify-content-between align-items-center align-content-center m-2 border-info p-2 border"
                v-for="img of pr.images" :key="img">
              <b-img-lazy class="" :src="makeImgUrl(img)" width="80"></b-img-lazy>
              <div>
                <a @click.prevent="removeImage(img)"
                   href="#"
                   class="btn btn-outline-danger btn-sm"
                   style="cursor: pointer">
                  <font-awesome-icon icon="times" size="2x"></font-awesome-icon>
                </a>
              </div>
            </div>
          </transition-group>
        </draggable>

      </div>

      <hr>
      <file-upload v-model="img" @input="addNewVideo" title="Video"></file-upload>
      <div class="d-flex flex-row justify-content-start mt-2 mb-2">
        <div class="d-flex flex-column mr-2 border-info p-2 border" v-for="img of pr.videos" :key="img">
          <a :href="img" target="_blank">View</a>
          <a @click="removeVideo(img)"
             class="d-flex align-content-end align-self-end align-items-end justify-content-end text-right mt-2"
             style="cursor: pointer">
            <font-awesome-icon icon="times" size="2x"></font-awesome-icon>
          </a>
        </div>
      </div>
      <hr>
      <b-form-group label="Category of Product">
        <b-form-select v-model="pr.category.id" :options="$store.state.categories" value-field="id"
                       text-field="name"></b-form-select>
      </b-form-group>
      <b-form-group label="Tax Rate">
        <b-form-select v-model="pr.taxPct.id" :options="$store.state.taxPct" value-field="id"
                       text-field="name"></b-form-select>
      </b-form-group>


      <b-form-group label="Variant Types">
        <b-form-tags v-model="pr.variantTypes" class="mb-2"></b-form-tags>
      </b-form-group>

      <table class="table table-sm table-borderless" v-if="pr.variantTypes.length > 0">
        <thead>
        <tr>
          <th>Variant Type</th>
          <th>Variant Options</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="vt of pr.variantTypes" :key="vt">
          <td>{{ vt }}</td>
          <td>
            <b-form-tags v-model="pr.variantMatrix[vt]" @change="populateVariants"></b-form-tags>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-between mt-3 mb-3">
        <h4>Variants</h4>
        <button class="btn btn-sm btn-info"
                @click.prevent="pr.variantList.push({name:'', weight:0.0, moq: 1, price:0.0, offerPrice: 0.0, active: true, matrix: {}, id: null})">
          <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
        </button>
      </div>

      <div class="d-flex flex-column">
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            <input type="checkbox" disabled>
          </b-input-group-prepend>
          <input class="form-control" disabled placeholder="Name"/>
          <select class="form-control" v-for="k of pr.variantTypes" :key="k" :placeholder="k" disabled>
            <option :value="k" selected>{{ k }}</option>
          </select>
          <input class="form-control" type="number" disabled placeholder="List Price"/>
          <input class="form-control" type="number" disabled placeholder="Offer Price"/>
        </b-input-group>
        <div v-for="(li, index) of pr.variantList" v-bind:key="index" flush>
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <input type="checkbox" v-model="li.active">
            </b-input-group-prepend>
            <b-form-input v-model="li.name" :disabled="!li.active" placeholder="Name"/>
            <b-form-select v-for="k of pr.variantTypes" :key="k"
                           :disabled="!li.active"
                           v-model="li.matrix[k]" :placeholder="k"
                           :options="variantOptions(k)"></b-form-select>
            <b-form-input type="number" :id="'tooltip-target-1'+index" v-model="li.price" :disabled="!li.active"
                          placeholder="List Price"/>
            <b-form-input type="number" :id="'tooltip-target-2'+index" v-model="li.offerPrice" :disabled="!li.active"
                          placeholder="Offer Price"/>
          </b-input-group>
          <b-tooltip :target="'tooltip-target-1'+index" triggers="hover">
            List Price (Before Discount)
          </b-tooltip>
          <b-tooltip :target="'tooltip-target-2'+index" triggers="hover">
            Offer Price (After Discount)
          </b-tooltip>

        </div>
      </div>
      <div class="mt-3 d-flex flex-column">
        <h5 class="mb-3">Instructions to Use</h5>
        <vue-editor
            :editorToolbar="customToolbar"
            v-model="pr.printDescription"></vue-editor>
      </div>

      <div class="d-flex justify-content-end">
        <b-button-group class="mt-3 text-end">
          <b-button @click="save">
            Save
          </b-button>
        </b-button-group>
      </div>
    </b-modal>
  </b-button>
</template>
<script>
import axios from 'axios'
import { uuid } from 'uuidv4'
import FileUpload from '@/components/file-upload'
import { AddProduct } from '../util'
import { VueEditor } from 'vue2-editor'
import draggable from 'vuedraggable'

export default {
  name: 'NewProduct',
  components: {FileUpload, VueEditor, draggable},
  data: () => ({
    img: '',
    id: uuid(),
    ings: '',
    pr: Object.assign({}, new AddProduct()),
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{list: 'ordered'}, {list: 'bullet'}]
    ]
  }),
  props: {
    product: Object,
    edit: Boolean
  },
  watch: {},
  created: function () {
    this.getProduct()
  },
  computed: {
    title () {
      return this.product.id ? this.product.name : 'New Product'
    },
    allTags () {
      return this.$store.state.allTags
    },
    allCollections () {
      return this.$store.state.collections
    },
    editVariant: function () {
      return this.edit
    }
  },
  methods: {
    variantOptions (k) {
      return this.pr.variantMatrix[k]
    },
    populateVariants () {

    },
    makeIngs () {
      if (this.ings) {
        this.pr.ingredients = this.ings.split(',').map(l => l.trim())
      }
    },
    addNewImage (a) {
      setTimeout(() => {
        this.pr.images.push(a)
      }, 1000)
    },
    addNewVideo (a) {
      setTimeout(() => {
        this.pr.videos.push(a)
      }, 1000)
    },
    removeImage (img) {
      let start = this.pr.images.indexOf(img)
      console.log('remove', img, 'index', start)
      this.pr.images.splice(start, 1)
    },
    removeVideo (img) {
      let start = this.pr.videos.indexOf(img)
      console.log('remove', img, 'index', start)
      this.pr.videos.splice(start, 1)
    },
    async getProduct () {
      this.pr = Object.assign({}, new AddProduct())
      if (this.product.id) {
        this.pr.id = this.product.id
        this.pr.name = this.product.name
        this.pr.webName = this.product.webName
        this.pr.barcode = this.product.barcode
        this.pr.subTitle = this.product.subTitle
        this.pr.description = this.product.description
        this.pr.mainImage = this.product.mainImage
        this.pr.images = this.product.images
        this.pr.category = this.product.category
        this.pr.taxPct = this.product.taxPct
        this.pr.weighingUnit = this.product.weighingUnit
        this.pr.ingredients = this.product.ingredients
        this.pr.selectTexture = this.product.selectTexture
        this.pr.printDescription = this.product.printDescription
        this.pr.variantTypes = this.product.variantTypes || []
        this.pr.variantMatrix = this.product.variantMatrix || {}
        this.pr.tags = this.product.tags
        this.pr.videos = this.product.videos || []
        this.ings = this.pr.ingredients.join(',')
        this.pr.variantList = (this.product.variants || []).map(li => {
          return {
            'id': li.id,
            'name': li.name,
            'weight': li.weight,
            'moq': li.moq,
            'price': li.price,
            'offerPrice': li.offerPrice,
            'active': li.active,
            'matrix': li.matrix,
            'subscribeable': li.subscribeable,
            'subscriptionPrice': li.subscriptionPrice
          }
        })
      }

    },
    async save () {

      let pvreq = []

      Object.keys(this.pr.variantMatrix).forEach(key => {
        let val = this.pr.variantMatrix[key]
        pvreq.push(val.length)
      })

      let reqCount = pvreq.reduce((p, v) => p * v, 1)
      if (reqCount > this.pr.variantList.length) {
        return
      }

      await axios.post('/api/admin/products', this.pr)
      this.$bvModal.hide('new-product-' + this.id)
      await this.$store.dispatch('loadProducts')
      await this.$emit('product-saved')
      await this.getProduct()
      await this.$bvModal.hide('new-product-' + this.id)
    },
    async openDialog () {
      let id = 'new-product-' + this.id
      if (this.category && this.category['id']) {
        this.product.category = {id: this.category.id}
      }
      if (!this.product.taxPct['id']) {
        this.product.taxPct = {id: this.$store.state.taxPct[0].id}
      }
      this.$bvModal.show(id)
    },
    makeImgUrl (p) {
      if (p.indexOf('http') === 0) {
        if (p.indexOf('https://ms-profiles') === 0) {
          let imgName = p.replace('https://ms-profiles.objectstore.e2enetworks.net/', '')
          return `https://themilletstore.imgix.net/${imgName}?ar=1&fit=clip&height=1024&ixlib=java-2.3.2&w=181&width=1024&auto=format`
        }
        return p
      }
      return `${process.env.VUE_APP_URL}/public/image/${p}?size=128`
    },
  }
}
</script>