<template>
  <admin-product-view :product="product" v-if="product.id"></admin-product-view>
</template>

<script>
import AdminProductView from '@/components/admin-product-view'

export default {
  name: 'ProductView',
  components: {AdminProductView},
  data: () => ({
    product: {}
  }),
  async created () {
    await this.loadProduct()
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  watch: {
    async id () {
      await this.loadProduct()
    }
  },
  methods: {
    async loadProduct () {
      let id = this.$route.params.id
      this.product = this.$store.state.products.filter(pp => pp.id === id)[0]
    }
  }
}
</script>

<style scoped>

</style>