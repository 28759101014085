<template>
  <order-display :order="order" v-if="order.id"></order-display>
</template>

<script>

import OrderDisplay from '@/components/order-display'
import axios from 'axios'
export default {
  name: 'OrderView',
  components: {OrderDisplay},
  data: () => ({
    order: {}
  }),
  async created(){
    await this.loadOrder()
  },
  computed: {
    id(){
      return this.$route.params.id
    }
  },
  watch: {
    async id(){
        await this.loadOrder()
    }
  },
  methods: {
    async loadOrder(){
      let id = this.$route.params.id
      this.order = (await axios.get(`/api/admin/orders/${id}?purpose=display`)).data
    }
  }
}
</script>

<style scoped>

</style>