<template id="admin-areas">
    <section>
        <div class="d-flex w-100 flex-column flex-md-row justify-content-md-between">
            <h4>Delivery Areas</h4>
            <div class="d-flex">
                <b-form-input v-model="filter" placeholder="Search..."></b-form-input>
            </div>
            <div class="d-flex justify-content-end mt-3 mt-md-0">
                <b-button v-b-modal.modal-area variant="info" size="sm">
                    <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
                    New Area
                </b-button>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-6 mt-3 justify-content-center">
          <div class="col" v-for="a  of filterArea" :key="a.id">
            <div class="card bg-transparent mb-3" >
              <div class="card-header d-flex w-100 justify-content-between">
                {{a.name}}
                <b-badge :variant="getVariant(a.areaType)">
                  {{a.areaType}}
                </b-badge>
              </div>
              <div class="card-body">

                <div class="list-group">

                  <div class="list-group-item">
                    Min Order: <rupee>{{a.minOrderValue}}</rupee>
                  </div>
                  <div class="list-group-item list-group-item" v-if="a.daysOfDelivery && a.daysOfDelivery.length > 0">
                    {{a.daysOfDelivery.join(', ')}}
                  </div>
                  <div class="list-group-item" v-else>
                    <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
                    No Delivery Days Assigned
                  </div>

                </div>
              </div>
              <div class="card-footer d-flex w-100 justify-content-end align-content-center align-items-center">
                <button class="btn btn-info" @click="editArea(a)">
                  <font-awesome-icon :icon="['far','edit']"></font-awesome-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <b-modal id="modal-area" title="New Area" @ok="save">
            <b-form>
                <b-form-group label="Name of Area">
                    <b-form-input v-model="area.name" type="text" required placeholder="Name"></b-form-input>
                </b-form-group>
                <b-form-group label="Address of Area">
                    <b-form-input v-model="area.address" type="text" required placeholder="Address"></b-form-input>
                </b-form-group>
                <b-form-group label="City">
                    <b-form-input v-model="area.city" type="text" required placeholder="City"></b-form-input>
                </b-form-group>
                <b-form-group label="Pin Code">
                    <b-form-input v-model="area.pinCode" type="text" required placeholder="Pin Code"></b-form-input>
                </b-form-group>
                <b-form-group label="Min order value">
                    <b-form-input v-model="area.minOrderValue" type="number" required placeholder="Min Order Value for Delivery"></b-form-input>
                </b-form-group>
                <b-form-group label="Area Type">
                    <b-form-select v-model="area.areaType" required :options="$store.state.areaTypes"></b-form-select>
                </b-form-group>
                <b-form-group label="Days Of Delivery">
                    <b-form-select v-model="area.daysOfDelivery" multiple required
                                   :options="$store.state.weekDays"></b-form-select>
                </b-form-group>

                <b-form-group label="Order Cut Off Time">
                    <b-form-timepicker v-model="area.cutOffTime" locale="en"></b-form-timepicker>
                </b-form-group>
            </b-form>
        </b-modal>
    </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
export default {
  components: {Rupee},
  data: () => ({
    areas: [],
    brands: [],
    area: {},
    filter: '',
    mergeWith: '',
  }),
  computed: {
    filterArea: function () {
      let find = this.filter.toLowerCase();
      if (find.length === 0) return this.areas
      else return this.areas.filter(a => a.name.toLowerCase().indexOf(find) > -1)
    }
  },
  created: function () {
    this.loadAreas()
    this.loadBrands()
  },
  methods: {
    getVariant(at){
      if (at === "APARTMENT") return "success";
      if (at === "STORE") return "warning";
      if (at === "ONLINE") return "primary";
      return "info"
    },
    getBrand(b){
      return this.brands.filter(ba => ba.id === b)[0].name
    },
    async mergeArea(a){
      setTimeout(async ()=>{
        await axios.put(`/api/admin/areas?from=${a}&to=${this.mergeWith}`)
        await this.loadAreas()
        this.mergeWith = null
      }, 300)
    },
    async loadAreas() {
      let resp = await axios.get('/api/admin/areas')
      this.areas = resp.data
    },
    async loadBrands() {
      let resp = await axios.get('/api/admin/brands')
      this.brands = resp.data
    },
    async editArea(a) {
      this.area = Object.assign({}, a)
      this.$bvModal.show('modal-area')
    },
    async save() {
      await axios.post('/api/admin/areas', this.area)
      await this.loadAreas()
    }
  }
}
</script>
<style>
</style>
